<template>
	<div class="auth-page">
		<div class="content">
			<div class="header">
				<div class="logo">
					<img src="../assets/images/logo.png" alt="logo" />
				</div>
			</div>
			<div class="data-container">
				<div class="left-content">
					<div class="login-text">
						<h3>
							<span>A private virtual Room</span> <span>to play with </span>
							<span> family &#38; friends</span>
						</h3>
						<p>
							<span>See</span> | <span>Talk</span> | <span>Play</span> |
							<span>Feel</span>
						</p>
					</div>					
					<b-btn class="gold-button" to="/register">Signup</b-btn>
				</div>
				<div class="right-content">
					<div class="auth-card">
						<p class="heading">Login</p>
						<input type="text"
							   class="input-box"
							   v-model.trim="email"
							   placeholder="Email"
							   name="username" />
						<input type="password"
							   class="input-box"
							   v-model="password"
							   @keyup.enter="login"
							   placeholder="Password"
							   name="password" />
						<div class="buttons">
							<processingbutton buttonclass="game-button" label="Login" :onclick="login" :isprocessing="isprocessing" />
							<router-link class="link-button" to="/passwordreset">Forgot Password</router-link>
						</div>
						<!--div class="social-button">
							<a class="icon-button">
								<img src="../assets/images/google.png" alt="google" />
								<p>login with Google</p>
							</a>
							<a class="icon-button">
								<img src="../assets/images/facebook.png" alt="facebook" />
								<p>login with Facebook</p>
							</a>
						</div-->
						<p class="register">
							Do you have a Gamecode?
							<router-link class="link-button" to="/">Play Now</router-link>
						</p>
					</div>
				</div>
			</div>
			<div class="auth-footer">
				<div class="footer-link text-muted font-weight-light">
					<a target="_blank" href="/privacy">Privacy</a> |
					<a target="_blank" href="/terms">Terms of use</a>  |
					<a target="_blanK" HREF="/howto">How to</a>
				</div>
				<p>© 2021 ZoomGameZ. All rights reserved.</p>
				<span id="siteseal"></span>
			</div>
		</div>
	</div>
</template>
<script lang="js">
	import { mapState, mapGetters } from 'vuex'
	import DEBUG from '@/common/DEBUG'
	import processingbutton from '@/components/processingbutton'

	export default {
		name: 'login',

		components: {
			processingbutton
		},

		data() {
			return {
				email: '',
				password: '',
			}
		},

		computed: {
			...mapState(
				['autosignin', 'isprocessing', 'haserror', 'error']
			),

			...mapGetters([
				'isuser_admin',
				'isuser_gamecreator',
				'isuser_gamehost',
			])
		},

		mounted() {
			/*var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://seal.godaddy.com/getSeal?sealID=18yoyMrc6A0NB4E21gQeub0dwHudXYMKZt6hiCVpFPQV8qNAk96643jYZ0Cy';
			$('#siteseal').append(script)*/
		},

		methods: {
			async login() {
				const that = this
				const email = that.email
				const password = that.password

				if (!email || !password) {
					alert('invalid user name/email or password specified')
					return
				}

				try {
					await that.$store.dispatch('login', { email, password })

					if (that.isuser_gamecreator || that.isuser_gamehost) {
						DEBUG.log('GAME CREATOR - goto home ...')
						await that.$router.push({ name: 'home' })
					} else if (that.isuser_admin) {
						DEBUG.log('ADMINISTRATOR - goto home ...')
						await that.$router.push({ name: 'home' })
					} else {
						DEBUG.log('GAME PLAYER - goto join game ...')
						await that.$router.push({ name: 'home' })
					}
				}
				catch (error) {
					DEBUG.log('ERROR in login - %o', error)
					that.notifyError(`Unable to login due to an error - ${error.message}`)
				}
			},
		}
	}
</script>